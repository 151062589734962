/* 
Light green: #7af8db
Light on green: #00201a
Light background: #fafdfa
Light on background: #191c1b
Dark green: #005143
Dark on green: #7af8db
Dark background: #191c1b
Dark on background: #e1e3e0
*/

/* Font */

@font-face {
  font-family: 'roboto_slab';
  src: url('fonts/robotoslab-variablefont_wght-webfont.woff2') format('woff2'),
       url('fonts/robotoslab-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_slab_light';
  src: url('fonts/robotoslab-light-webfont.woff2') format('woff2'),
       url('fonts/robotoslab-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_mono';
  src: url('fonts/robotomono-variablefont_wght-webfont.woff2') format('woff2'),
       url('fonts/robotomono-variablefont_wght-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'rubik_light';
  src: url('fonts/rubik-light-webfont.woff2') format('woff2'),
       url('fonts/rubik-light-webfont.woff') format('woff');
  font-weight: lighter;
  font-style: lighter;
}

@font-face {
  font-family: 'rubik';
  src: url('fonts/rubik-regular-webfont.woff2') format('woff2'),
       url('fonts/rubik-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'rubik_bold';
  src: url('fonts/rubik-bold-webfont.woff2') format('woff2'),
       url('fonts/rubik-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: bold;
}

/* Global */

ul {
  padding-inline-start: 1em;
}

.App {
  background-color: #7af8db;
}

.content {
  background-color: #fafdfa;
}

.content-container {
  max-width: 1000px;
  margin: auto;
  min-height: calc(100vh - 60px);
}

.section-header {
  font-family: roboto_slab;
  font-size: 22px;
  padding: 54px 0px 8px 0px;
  margin: 0 32px 54px 32px;
  border-bottom: 1px solid #7af8db;
  font-weight: normal;
}

.section-title {
  font-family: rubik;
  font-weight: normal;
  font-size: 20px;
  color: #191c1b;
  text-decoration: none;
}

.section-text {
  font-family: rubik;
  font-weight: normal;
  font-size: 18px;
  color: #191c1b;
  text-decoration: none;
  line-height: 1.15;
}

.section-filter {
  font-family: roboto_mono;
  font-weight: normal;
  font-size: 16px;
  color: #191c1b;
  text-decoration: none;
  cursor: pointer;
  padding: 4px 0;
}

.section-link {
  background-color: #7af8db;
  color: #00201a;
  font-family: roboto_mono;
  font-size: 16px;
  padding: 16px 32px;
  width: fit-content;
  cursor: pointer;
  word-break: break-word;
}

a:link {
  text-decoration: none;
}

/* Header */

.header {
  background-color: #7af8db;
  width: 100%;
  text-align: center;
  position: fixed;
  z-index: 100;
  height: 60px;
}

.header-content {
  margin: 0 auto;
  height: 100%;
}

.top-bar-container {
  display: flex;
  background-color: #7af8db;
  max-width: 1000px;
  margin: auto;
  height: 100%;
  align-items: center;
  justify-content: space-between;
}

.logo {
  padding: 16px 24px 16px 24px;
  margin-left: 8px;
  width: 32px;
  filter: invert(10%) sepia(21%) saturate(1984%) hue-rotate(123deg) brightness(97%) contrast(103%);
}

.link-container {
  font-size: 16px;
}

.link {
  color: #00201a;
  font-family: roboto_mono;
  flex: 0 1 auto;
  cursor: pointer;
  padding: 16px 32px;
  z-index: 100;
  text-align: end;
  font-size: 16px;
}

/* Home */

.home-header {
  background-color: #7af8db;
  height: 100vh;
  width: 100%;
}

.header-centered-content-container {
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  max-width: 1000px;
  margin: auto;
}

.header-centered-content {
  z-index: 40;
  margin: 0px 32px;
}

.header-title {
  font-family: rubik_bold;
  color: #00201a;
  font-size: 72px;
  margin: 40px 0px 0px 0px;
}

.header-image {
  width: 300px;
  box-shadow: 9px 9px 0px -4px #7af8db, 9px 9px 0px 0px #00201a;
}

.header-subtitle {
  font-family: roboto_mono;
  color: #00201a;
  font-size: 40px;
  margin: 0;
}

.home-filters-container {
  display: flex;
  margin: 0 32px 32px 32px;
  padding-top: 64px;
  gap: 32px;
}

.home-projects-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1200px;
  margin: 32px 32px 0 32px;
  gap: 32px;
  padding-bottom: 32px;
}

.home-project-container {
  padding-bottom: 32px;
}

.home-project-image-container {
  z-index: 20;
  position: relative;
  margin-bottom: 16px;
  line-height: 0;
}

.home-project-image {
  width: 100%;
}

.home-project-image-tag {
  background-color: #7af8db;
  position: absolute;
  bottom: 12px;
  left: 12px;
  color: #00201a;
  padding: 8px 16px;
  font-family: roboto_mono;
  line-height: normal;
}

/* About */

.about-container {
  padding-top: 60px;
}

.about-title {
  font-size: 72px;
  font-family: rubik_bold;
  margin: 32px 0 0 32px;
}

.about-content-container {
  display: flex;
  gap: 32px;
}

.about-text-container {
  flex: 1 1 0;
  padding: 16px 0 32px 0;
}

.about-image-container {
  flex: 1 1 0;
  padding: 16px 0 32px 0;
}

.about-text {
  margin: 0px 32px 0 32px;
  padding-bottom: 16px;
}

.about-image {
  width: calc(100% - 40px);
  margin: 0px 32px 0 0;
  box-shadow: 9px 9px 0px -4px #fff, 9px 9px 0px 0px #191c1b;
}

/* Project */

.project-container {
  padding-top: 60px;
}

.project-header-container{
  display: flex;
  gap: 64px;
  margin: 0 32px;
  padding: 32px 0;
}

.project-image {
  width: 100%;
}

.project-title {
  font-size: 72px;
  font-family: rubik_bold;
}

.project-subtitle {
  font-family: roboto_mono;
  font-size: 40px;
  padding-top: 16px;
}

.project-header-image-container {
  flex: 1 1 0;
}

.project-header-text-container {
  flex: 2 1 0;
}

.project-body-container {
  padding: 32px 0;
}

.project-detail-title {
  font-family: roboto_mono;
  font-weight: 600;
  padding-top: 16px;
  font-size: 16px;
  color: #191c1b;
}

.project-detail-value {
  font-family: roboto_mono;
  font-size: 16px;
  color: #191c1b;
}

.project-nav-container {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
  gap: 8px;
}

.project-nav-back {
  flex-basis: 100%;
}

.project-nav-forward {
  text-align: end;
  flex-basis: 100%;
}

.project-nav-icon {
  width: 20px;
  filter: invert(7%) sepia(11%) saturate(487%) hue-rotate(109deg) brightness(89%) contrast(90%);
}

/* Footer */

.footer {
  background-color: #7af8db;
  width: 100%;
  text-align: center;
  z-index: 100;
  height: 60px;
}

.footer-content {
  margin: 0 auto;
  height: 100%;
}

.bottom-link-container {
  display: flex;
  background-color: #7af8db;
  max-width: 1000px;
  margin: auto;
  height: 100%;
  align-items: center;
}

.contact-icon {
  width: 24px;
  padding: 16px;
  cursor: pointer;
  filter: invert(10%) sepia(21%) saturate(1984%) hue-rotate(123deg) brightness(97%) contrast(103%);
}

@media (prefers-color-scheme: dark) {
  html {
    background-color: #005143;
  }

  .App {
    background-color: #005143;
  }

  .content {
    background-color: #121212;
  }

  .section-header {
    color: #e1e3e0;
    border-bottom: 1px solid #69F0AE;
  }

  .section-title {
    color: #e1e3e0;
  }

  .section-text {
    color: #e1e3e0;
  }

  .section-subtext {
    color: #e1e3e0;
  }

  .section-filter {
    color: #e1e3e0;
  }

  .section-link {
    background-color: #005143;
    color: #e1e3e0;
  }

  .project-title {
    color: #e1e3e0;
  }

  .project-nav-icon {
    filter: invert(85%) sepia(3%) saturate(153%) hue-rotate(56deg) brightness(112%) contrast(84%);


  }

  .top-bar-container {
    background-color: #005143;
  }

  .header {
    background-color: #005143;
  }

  .home-header {
    background-color: #005143;
  }

  .home-project-image-tag {
    background-color: #005143;
    color: #7af8db;
  }

  .project-detail-title {
    color: #e1e3e0;
  }
  
  .project-detail-value {
    color: #e1e3e0;
  }

  .link {
    color: #7af8db;
  }

  .header-title {
    color: #7af8db;
  }

  .header-subtitle {
    color: #7af8db;
  }

  .header-image {
    box-shadow: 9px 9px 0px -4px #005143, 9px 9px 0px 0px #7af8db;
  }

  .about-title {
    color: #e1e3e0;
  }

  .about-image {
    box-shadow: 9px 9px 0px -4px #121212, 9px 9px 0px 0px #e1e3e0;
  }

  .bottom-link-container {
    background-color: #005143;
  }

  .footer {
    background-color: #005143;
  }

  .logo {
    filter: invert(94%) sepia(20%) saturate(1006%) hue-rotate(87deg) brightness(101%) contrast(94%)
  }

  .contact-icon {
    filter: invert(94%) sepia(20%) saturate(1006%) hue-rotate(87deg) brightness(101%) contrast(94%)
  }
}

@media only screen and (max-width: 800px) {
  .home-projects-container{
    grid-template-columns: repeat(2, 1fr);
  }

  .project-title {
    font-size: 52px;
  }
}

@media only screen and (max-width: 600px) {
  .header-title {
    font-size: 48px;
  }

  .header-subtitle {
    font-size: 30px;
  }

  .home-projects-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .project-header-container {
    flex-direction: column;
    gap: 16px;
  }

  .about-title {
    font-size: 48px;
  }

  .about-content-container {
    flex-direction: column;
    gap: 0;
  }

  .about-image-container {
    margin-left: 32px;
    padding: 0 0 32px 0;
  }

  .about-text-container {
    padding-bottom: 16px;
  }

}

@media only screen and (max-width: 480px) {
  .home-filters-container {
    gap: 16px
  }
}

@media only screen and (max-width: 400px) {
  .header-image {
    width: 200px;
  }

  .home-filters-container {
    flex-direction: column;
    gap: 0;
  }
}

@media only screen and (max-width: 350px) {
  .project-title {
    font-size: 40px;
  }
}

@media only screen and (max-height: 630px) {
  .header-image {
    width: 200px;
  }

  .header-title {
    font-size: 48px;
  }

  .header-subtitle {
    font-size: 30px;
  }
}

@media only screen and (max-height: 522px) {
  .header-image {
    display: none;
  }
}
